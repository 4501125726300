import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Col,
  Dropdown,
  Row,
  Table,
  Image,
  Button,
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import FilterSlider from '../FilterSlider/FilterSlider'
import { useDispatch, useSelector } from 'react-redux'
import { Plans, PaginatePlans } from '../../../redux/plans/planActions'
import { Spinner } from '../../Common/Spinner/Spinner'
import InfiniteScroll from 'react-infinite-scroll-component'
const ProviderTable = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const url = useParams()

  const [visible, setVisible] = useState(false)
  const [sizecQueryParam, setSizeQueryParam] = useState([])
  const [dayQueryParam, setDayQueryParam] = useState([])
  const [priceQueryParam, setPriceQueryParam] = useState([])

  const [sizeRange, setSizeRange] = useState([0, 10])
  const [sizeRangeSymbol, setSizeRangeSymbol] = useState('+')


  const [dayRange, setDayRange] = useState([1, 30])
  const [dayRangeSymbol, setDayRangeSymbol] = useState('+')

  const [priceRange, setPriceRange] = useState(['1.00', '100.00'])
  const [PriceRangeSymbol, setPriceRangeSymbol] = useState('+')
  const [selectedItem, setSelectedItem] = useState('Cheapest')


  // Go to top (Arrow Button)
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 40) {
      setVisible(true)
    }
    else if (scrolled <= 40) {
      setVisible(false)
    }
  };


  /**SET SORTING BY CHEAPEST Best price/GB ,Largest GB ,Longest validity**/
  const HandleSelectedItem = (e) => {
    setSelectedItem(e)
  }
  /**SET RANGE FILTER**/
  function sizeRangeChange(newValue) {
    setSizeRange(newValue)
    if (newValue[1] < 10) {
      setSizeRangeSymbol('')
    } else {
      setSizeRangeSymbol('+')
    }
    // setSizeQueryParam(newValue)
  }
  function dayRangeChange(newValue) {
    setDayRange(newValue)
    if (newValue[1] < 30) {
      setDayRangeSymbol('')
    } else {
      setDayRangeSymbol('+')
    }
    // setDayQueryParam(newValue)
  }
  function priceRangeChange(newValue) {
    setPriceRange(newValue)
    if (newValue[1] < 100.00) {
      setPriceRangeSymbol('')
    } else {
      setPriceRangeSymbol('+')
    }
    // setPriceQueryParam(newValue)
  }

  function sizeRangeQueryParamChange(newValue) {
  
    setSizeQueryParam(newValue)
  }
  function dayRangeQueryParamChange(newValue) {
    
    setDayQueryParam(newValue)
  }
  function priceRangeQueryParamChange(newValue) {
   
    setPriceQueryParam(newValue)
  }

  /**END**/


  // PLAN API RESPONSE
  const total_Plans = useSelector((state) => state?.plans?.plansList?.total_records)
  const total_providers = useSelector((state) => state?.plans?.plansList?.total_providers)
  const Loading = useSelector((state) => state?.plans?.loading)
  const currentPage = useSelector((state) => state?.plans?.currentPage)
  const nextPage = useSelector((state) => state?.plans?.nextPage)
  const plans = useSelector((state) => state?.plans?.items)
  const hasMore = useSelector((state) => state?.plans?.hasMore)


  // Render Plans data
  const fetchPlans = useCallback(() => {
    dispatch(
      Plans(
        url.slug,
        sizecQueryParam,
        dayQueryParam,
        priceQueryParam,
        selectedItem,
        currentPage
      ),
    )
  }, [sizecQueryParam, dayQueryParam, priceQueryParam, selectedItem, url.slug, currentPage, dispatch])

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible); // call toggle button
    fetchPlans()

  }, [sizecQueryParam, dayQueryParam, priceQueryParam, selectedItem, url.slug, fetchPlans])


  // RENDER PAGINATE DATA (marge in previous data)
  const fetchPaginatePlans = () => {

    dispatch(
      PaginatePlans(
        url.slug,
        sizecQueryParam,
        dayQueryParam,
        priceQueryParam,
        selectedItem,
        nextPage
      ),
    )
  }

  return (
    <div className="plans-table-wrapper position-relative">
      <div className="container-width">
        <Row className="mb-xxl-5 mb-xl-5 mb-3">
          <Col sm="5">
            <div className="plans-table-info d-flex align-items-center justify-content-lg-start justify-content-center mb-lg-0 mb-4">
              <p className="me-lg-4 me-md-4 me-sm-4 me-0 mb-lg-0 mb-md-0 mb-sm-0 mb-3">
                <span className="orange">{total_providers}</span> eSIM Providers
              </p>
              <p className="mb-0">
                <span className="orange">{total_Plans}</span> eSIM prepaid data
                plans found
              </p>
            </div>
          </Col>
          <Col sm="7">
            <div className="button-table d-flex justify-content-end">
              <div class="button-group-select desktop-view me-5 mb-lg-0 mb-3">
                <button
                  class={selectedItem === 'Cheapest' ? 'active' : ''}
                  onClick={() => HandleSelectedItem('Cheapest')}
                >
                  Cheapest
                </button>
                <button
                  class={selectedItem === 'Best price/GB' ? 'active' : ''}
                  onClick={() => HandleSelectedItem('Best price/GB')}
                >
                  Best price/GB
                </button>
                <button
                  class={selectedItem === 'Largest GB' ? 'active' : ''}
                  onClick={() => HandleSelectedItem('Largest GB')}
                >
                  Largest GB
                </button>
                <button
                  class={
                    selectedItem === 'Longest validity' ? 'active' : ''
                  }
                  onClick={() => HandleSelectedItem('Longest validity')}
                >
                  Longest validity
                </button>
              </div>
              <div className="dropdowns-wrapper plans-dropdowns flex-lg-row flex-md-row flex-sm-row flex-column d-flex justify-content-lg-end justify-content-center">
                <Dropdown
                  id="dropdown-button-drop-down"
                  drop="down"
                  className="me-lg-3 me-md-3 me-sm-3 me-0 mb-lg-0 mb-md-0 mb-sm-0 mb-3"
                >
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="d-flex align-items-center drop-curr-wrapper "
                  >
                    <div className="drop-curr d-flex align-items-center">
                      <h6 className="mb-0">Advanced Search</h6>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="plans-custom-dropdown">
                    <FilterSlider
                      min={0}
                      max={10}
                      min_symbol="GB"
                      mix_symbol={'GB'}
                      plus_symbol={sizeRangeSymbol}
                      title="Size"
                      range={sizeRange}
                      step={1}
                      onChange={sizeRangeChange}
                      onFinalChange={sizeRangeQueryParamChange}
                    />
                    <FilterSlider
                      min={1}
                      max={30}
                      min_symbol="Day"
                      mix_symbol={'Days'}
                      plus_symbol={dayRangeSymbol}
                      title="Validity"
                      range={dayRange}
                      step={1}
                      onChange={dayRangeChange}
                      onFinalChange={dayRangeQueryParamChange}
                    />
                    <FilterSlider
                      min={1.0}
                      max={100.0}
                      min_symbol={"$"}
                      mix_symbol={'$'}
                      plus_symbol={PriceRangeSymbol}
                      title="Price"
                      range={priceRange}
                      step={0.1}
                      onChange={priceRangeChange}
                      onFinalChange={priceRangeQueryParamChange}
                    />
                  </Dropdown.Menu>
                </Dropdown>

              </div>
            </div>
          </Col>
        </Row>
        <div class="button-group-select mobile-viewe me-xxl-5 me-xl-5 me-0 mb-lg-0 mb-3">
          <button
            class={selectedItem === 'Cheapest' ? 'active' : ''}
            onClick={() => HandleSelectedItem('Cheapest')}
          >
            Cheapest
          </button>
          <button
            class={selectedItem === 'Best price/GB' ? 'active' : ''}
            onClick={() => HandleSelectedItem('Best price/GB')}
          >
            Best price/GB
          </button>
          <button
            class={selectedItem === 'Largest GB' ? 'active' : ''}
            onClick={() => HandleSelectedItem('Largest GB')}
          >
            Largest GB
          </button>
          <button
            class={selectedItem === 'Longest validity' ? 'active' : ''}
            onClick={() => HandleSelectedItem('Longest validity')}
          >
            Longest validity
          </button>
        </div>
        {Loading ? (
          <Spinner />
        ) : (
          <div className="table-responsive plans-table">
            {
              plans ?
                <InfiniteScroll
                  dataLength={plans?.length} //This is important field to render the next data
                  next={() => {
                    fetchPaginatePlans();
                  }}
                  hasMore={hasMore}
                  loader={<p style={{ textAlign: "center" }}>
                    {/* <b>Loading</b> */}
                  </p>}
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      {/* <b>Yay! You have seen it all</b> */}
                    </p>
                  }

                >
                  <Table hover>
                    <thead>
                      <tr>
                        <th>PROVIDER</th>
                        <th>Plan Name</th>
                        <th>SIZE</th>
                        <th>VALIDITY</th>
                        <th>PRICE/GB</th>
                        <th>PRICE</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      {plans?.length > 0 ? (
                        plans?.map((item, index) => (
                          <tr
                            onClick={() =>
                              navigate('/esim/' + url.slug + '/' + item.providerSlug)
                            }
                          >
                            <td className="d-flex align-items--center">
                              <div className="plans-table-img me-2">
                                <Image src={item.logo} />
                              </div>
                              {item.provider}
                            </td>
                            <td> {item.planTitle}</td>
                            <td>
                              {' '}
                              {item.capacity} {item.capacityUnit}
                            </td>
                            <td>{item.duration}</td>
                            <td>{item.pricePerGb}</td>
                            <td>{item.price}</td>
                            <td>
                              <FontAwesomeIcon
                                icon={faAngleRight}
                                className="orange"
                              />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colspan="6">
                            <p className="text-center mb-0">No Plan Found</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </InfiniteScroll>
                : ""
                }
          </div>
        )}
        {plans?.length > 0 &&
          plans?.map((item, index) => (
            <div
              className="mobile-view-provider-list"
              onClick={() =>
                navigate('/esim/' + url.slug + '/' + item.providerSlug)
              }
            >
              <div className="provider-data">
                <div className="mobile-view-plans d-flex justify-content-between align-items-center">
                  <div className="plans-table-img me-2 d-flex align-items-center">
                    <Image src={item.logo} fluid />
                    <div className="ms-2">
                      <p className="mb-0 mobile-text"> {item.planTitle}</p>
                      <div className="d-flex align-items-center">
                        <p className="mb-0">
                          {' '}
                          {item.capacity} {item.capacityUnit}
                          <span className='me-2'> | </span>
                        </p>
                        <p className="mb-0">{item.duration}</p>
                      </div>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center">
                    <div>
                      <p className="mb-0">
                        {item.pricePerGbForMobile}
                      </p>
                      <p className="mb-0">
                        <strong>
                          {item.price}
                        </strong>
                      </p>
                    </div>
                    <div className="ms-3">
                      <FontAwesomeIcon icon={faAngleRight} className="orange" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      {visible ?
        <div className="table-scroll text-end mb-5">
          <Button className="scroll-btn d-flex justify-content-center align-items-center" onClick={() => window.scrollTo(0, 0)}>
            <FontAwesomeIcon icon={faAngleRight} />
          </Button>
        </div>
        : ""}

    </div>
  )
}
export default ProviderTable
