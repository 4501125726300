import React, { useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { RegionsAndCountries } from '../../../redux/home/homeActions'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Row, Image } from 'react-bootstrap'
import regionimg from '../../../assets/images/region-img.png'
import { Spinner } from '../../Common/Spinner/Spinner'
const AboutInfoBoxes = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(RegionsAndCountries())
  }, [dispatch])

  const regions_and_countries = useSelector(
    (state) => state?.home?.regionsAndCountriesList?.data,
  )

  const settings = useSelector((state) => state?.settings?.settingsData?.data?.settings)
  const Loading = useSelector((state) => state?.home?.loading)

  return (
    <div className="about-boxes-wrapper">
      {Loading ? (
        <Spinner />
      ) : (
        <>
          <div className='container-width box-heading'>
            <h4 className='mb-4'>Popular Destinations</h4>
          </div>

          <div className="container-width about-boxes">


            <Row>
              <Col lg="12" className="mb-lg-0 mb-4">
                {/* <div className="about-boxes-content">
                  <h4>Popular Destinations</h4>
                  <p>
                    Proin ex ipsum, facilisis id tincidunt sed, vulputate in
                    lacus. Dune Donec pharetra faucibus leo.
                  </p>
                  <Button className="orange-btn desktop-view">See All</Button>
                </div> */}
                <Row>
                  {regions_and_countries?.top_destinations?.length > 0 &&
                    regions_and_countries?.top_destinations?.map(
                      (item, index) => (
                        <Col lg="4" className="mb-3">
                          <Link to={`/esim/${item.country_slug}`}>
                            <div className="about-country-flags d-flex align-items-center justify-content-between">
                              <p>{item.name}</p>
                              <div className="about-country-flags-img">
                                <Image src={item.flag_image} fluid />
                              </div>
                            </div>
                          </Link>
                        </Col>
                      ),
                    )}
                  {/* <div>
                    <Button className="orange-btn mobile-view" onClick={() => navigate('/regions')}>See All</Button>
                  </div> */}
                </Row>
              </Col>
            </Row>
          </div>
          <div className="container-width about-boxes">
            <Row>
              <Col lg="4" className="mb-lg-0 mb-4">
                <div className="about-boxes-content">
                  <h4>Regions</h4>
                  <p>
                    {settings?.regions_description &&
                      settings.regions_description
                    }
                  </p>
                  <Button
                    className="orange-btn desktop-view"
                    onClick={() => navigate('/regions')}
                  >
                    See All
                  </Button>
                </div>
              </Col>
              <Col lg="8">
                <Row>
                  {regions_and_countries?.regions?.length > 0 &&
                    regions_and_countries?.regions?.map((item, index) => (
                      <Col lg="6" className="mb-3">
                        <Link to={`/esim/${item.slug}`}>
                          <div className="about-country-flags d-flex align-items-center justify-content-between">
                            <p>{item.name}</p>
                            <div className="about-country-flags-img about-country-flags-img-globel about-region-flag">
                              <Image src={regionimg} fluid />

                            </div>
                          </div>
                        </Link>
                      </Col>
                    ))}
                  <div>
                    <Button className="orange-btn mobile-view" onClick={() => navigate('/regions')}>See All</Button>
                  </div>
                </Row>
              </Col>
            </Row>
          </div>
          {regions_and_countries?.regions?.length > 0 &&
            regions_and_countries?.regions.map((item, index) => (
              <div className="container-width about-boxes">
                <Row>
                  <Col lg="4" className="mb-lg-0 mb-4">
                    <div className="about-boxes-content">
                      <h4>{item.name}</h4>
                      <p>
                        {item.description}
                      </p>
                      <Button
                        className="orange-btn desktop-view"
                        onClick={() => navigate('/region/' + item.slug)}
                      >
                        See All
                      </Button>
                    </div>
                  </Col>
                  <Col lg="8">
                    <Row>
                      {item.countries.map((item, index) => (
                        <Col lg="6" className="mb-3">
                          <Link to={`/esim/${item.country_slug}`}>
                            <div className="about-country-flags d-flex align-items-center justify-content-between">
                              <p>{item.name}</p>
                              <div className="about-country-flags-img">
                                <Image src={item.flag_image} fluid />
                              </div>
                            </div>
                          </Link>
                        </Col>
                      ))}
                      <div>
                        <Button className="orange-btn mobile-view" onClick={() => navigate('/region/' + item.slug)}>See All</Button>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </div>
            ))}
        </>
      )}
    </div>
  )
}

export default AboutInfoBoxes
