import React, { useEffect } from "react";
import Banner from "../Contactus/Banner/Banner";
import Header from "../Home/Header/Header";
import Getin from "./Getin/Getin";
import Footer from "../Home/Footer/Footer";
import { ToastContainer } from 'react-toastify';
const Contactus =()=>{
    useEffect(() => {
    window.scrollTo(0, 0)
    }, [])
    return(
        <div className="wrapper">
            <ToastContainer/>
            <Header/>
            <Banner/>
            <Getin/>
            <Footer/>

        </div>

    )
}
export default Contactus;