import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Image, Tab, Nav } from 'react-bootstrap';
import { Spinner } from "../../Common/Spinner/Spinner";
import { fetchPosts, fetchCategories } from '../../../redux/blogPost/blogPostActions';
import { useDispatch, useSelector } from "react-redux";

const BlogInfo = () => {
    const dispatch = useDispatch();
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [filteredPosts, setFilteredPosts] = useState([]);
    const { postList } = useSelector((state) => state);
    const loading = postList?.loading;
    const categories = postList?.categories?.data;
    const posts = postList?.posts?.data;
    useEffect(() => {
        dispatch(fetchPosts());
        dispatch(fetchCategories());
    }, [dispatch]);

    useEffect(() => {
        if (selectedCategory && posts) {
            const filtered = posts.filter(post => post.category_name === selectedCategory);
            setFilteredPosts(filtered);
        } else {
            setFilteredPosts(posts);
        }
    }, [selectedCategory, posts]);

    const handleTabClick = (name) => {
        setSelectedCategory(name);
    };

    return (
        <div className="blog-info">
            <div className="container-width">
                {loading ? (
                    <Spinner />
                ) : (
                    <>
                        <Tab.Container defaultActiveKey="all" className="blog-tab-container">
                            <Nav variant="tabs" className="blog-tab-nav">
                                <Nav.Item>
                                    <Nav.Link eventKey="all" onClick={() => handleTabClick(null)} className={`blog-tab-link ${selectedCategory === null ? 'active' : ''}`}>All</Nav.Link>
                                </Nav.Item>
                                {categories && categories.map(category => (
                                    <Nav.Item key={category.name}>
                                        <Nav.Link eventKey={category.name} onClick={() => handleTabClick(category.name)} className={`blog-tab-link ${selectedCategory === category.name ? 'active' : ''}`}>{category.name}</Nav.Link>
                                    </Nav.Item>
                                ))}
                            </Nav>
                            <Tab.Content className="blog-tab-content">
                                {posts && posts.length === 0 &&
                                    <Tab.Container className="blog-tab-container">
                                        <div className="blog-not-found">Blog Not found</div>
                                    </Tab.Container>
                                }
                                <Tab.Pane eventKey="all">
                                    <div className="mb-lg-4 mb-4 row">
                                        {filteredPosts?.map((post) => (
                                            <div className="mb-lg-0 mb-4 col-lg-4" key={post.id}>
                                                <Link to={`/blog/${post.slug}`}>
                                                    <div className="blog-boxes-content">
                                                        <h4>{post.title}</h4>
                                                        <div className="about-blog-post-img blog-post-image">
                                                            <Image src={post.image} fluid />
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                </Tab.Pane>
                                {categories && categories.map(category => (
                                    <Tab.Pane eventKey={category.name} key={category.name}>
                                        <div className="mb-lg-4 mb-4 row">
                                            {filteredPosts?.map((post) => (
                                                post.category_name === category.name && (
                                                    <div className="mb-lg-0 mb-4 col-lg-4" key={post.id}>
                                                        <Link to={`/blog/${post.slug}`}>
                                                            <div className="blog-boxes-content">
                                                                <h4>{post.title}</h4>
                                                                <div className="about-blog-post-img blog-post-image">
                                                                    <Image src={post.image} fluid />
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                )
                                            ))}
                                        </div>
                                    </Tab.Pane>
                                ))}
                            </Tab.Content>
                        </Tab.Container>
                    </>
                )}
            </div>
        </div>
    );
}

export default BlogInfo;
