import React from "react";

import { Bars } from "react-loader-spinner";


export const Spinner = () => {


    return (
        (
            <div className="spinner">
               
                <Bars
                    height="80"
                    width="80"
                    color="#FA6400"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />

            </div>
        )
    );
};

