import React, { useEffect } from "react";
import Footer from "../Home/Footer/Footer";
import Header from "../Home/Header/Header";
import Banner from "./Banner/Banner";
import PlanBuy from "./PlanBuy/PlanBuy";
import PlanOffered from "./PlanOffered/PlanOffered";
import PlansDataBoxes from "./PlansDataBoxes/PlansDataBoxes";
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
const Plans = () => {
    const navigate = useNavigate()
    // Scroll to Top on page Load
    useEffect(() => {
    window.scrollTo(0, 0)
    }, [])

    const options = useSelector((state) => state?.countriesAndRegion?.searchRegionAndCountriesList?.data)
    // Header Search Redirection
    const handleClick = (e) => {
        const regionOrCountry = options.find((item) => item.value === e.value)
        if (regionOrCountry.region_id === '') {
            navigate(`/esim/${e.value}`)
            window.scrollTo(0, 0)
        } else {
            navigate(`/esim/${e.value}`)
            window.scrollTo(0, 0)
        }
    }
    return(
        <div className="wrapper">
            <Header handleClick={handleClick}/>
            <Banner/>
            <PlansDataBoxes/>
            <PlanBuy/>
            <PlanOffered/>
            <Footer/>
        </div>
    )
}
export default Plans;