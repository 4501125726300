import { FETCH_REQUEST, FETCH_POSTS, FETCH_POST, FETCH_CATEGORIES, ERROR_STATE } from "./blogPostTypes";
import { apiHelper } from "../apiHelper"
import 'react-toastify/dist/ReactToastify.css';

export const fetchRequest = () => ({
  type: FETCH_REQUEST
});
// Action creator for fetching a post by its slug from the server
export const fetchPost = (slug) => async (dispatch) => {
  try {
    dispatch(fetchRequest());
    // Send a GET request to the server to fetch the post data based on the provided slug
    let res = await apiHelper("get", `post?slug=${slug}`, '')

    // Check if response data exists
    if (res?.data) {
      // If data is received, extract the post data
      let { data } = res
      // Dispatch an action with the fetched post data
      dispatch({
        type: FETCH_POST,
        payload: data
      })
    } else {
      // If no data received, dispatch an action to indicate error state
      dispatch({
        type: ERROR_STATE
      })
    }
  } catch (error) {
    // If an error occurs during the fetch process, log the error
    console.log(error);
  }
}

// Action creator for fetching all posts from the server
export const fetchPosts = () => {
  // Return an async function that dispatches actions
  return async (dispatch) => {
    dispatch(fetchRequest());
    try {

      // Send a GET request to the server to fetch all posts
      let res = await apiHelper("get", "all-posts", '')
        
      // Check if response data exists
      if (res?.data) {
        // If data is received, extract the posts data
        let { data } = res
        // Dispatch an action with the fetched posts data
        dispatch({
          type: FETCH_POSTS,
          payload: data
        })
      } else {
        // If no data received, dispatch an action to indicate error state
        dispatch({
          type: ERROR_STATE
        })
      }
    } catch (error) {
      // If an error occurs during the fetch process, log the error
      console.log(error);
    }
  };
};

// Action creator for fetching all categories from the server
export const fetchCategories = () => {
  // Return an async function that dispatches actions
  return async (dispatch) => {
    dispatch(fetchRequest());
    try {
      // Send a GET request to the server to fetch all categories
      let res = await apiHelper("get", "all-categories", '')
        
      // Check if response data exists
      if (res?.data) {
        // If data is received, extract the categories data
        let { data } = res
        // Dispatch an action with the fetched categories data
        dispatch({
          type: FETCH_CATEGORIES,
          payload: data
        })
      } else {
        // If no data received, dispatch an action to indicate error state
        dispatch({
          type: ERROR_STATE
        })
      }
    } catch (error) {
      // If an error occurs during the fetch process, log the error
      console.log(error);
    }
  };
};


