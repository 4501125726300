import { REGIONS_AND_COUNTRIES } from './homeTypes';

const initialState = {
   user: [],
   regionsAndCountriesList: [],
   success: false,
   loading:true,
   registered: false,
   error: false
}

const homeReducer = (state = initialState, action) => {
   switch (action.type) {
      case REGIONS_AND_COUNTRIES:
         return {
            ...state,
            regionsAndCountriesList: action.payload,
            success: true,
            loading:false,
            registered: true
         }

      default:
         return state
   }
}

export default homeReducer
