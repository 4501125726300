import React from "react";

const Banner = (props) => {
    return (
        <div className="second-banner terms-banner">
            <div className="container-width">
                <div className="second-banner-content text-center">
                    <h1 ><span className="orange">TERMS</span></h1>
                    <p>{props.description_2}</p>
                </div>
            </div>
        </div>
    )
}
export default Banner;