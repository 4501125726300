import React, { useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { ContactUs } from '../../../redux/contactUs/contactUsActions';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
const Getin = () => {
    const dispatch = useDispatch();
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const contactUs = useSelector((state) => state?.contactUs?.message)
    useEffect(() => {
        if (contactUs?.status === 1) {
            reset()
        }
    }, [contactUs,reset])
    const onSubmit = async (data) => {
        dispatch(ContactUs(data));

    }


    return (
        <div className="get-in">
            <div className="container-width">
                <div className="getin">
                    <h4>Get in Touch</h4>

                    <p>eSIMLIST does not provide an eSIM service or sell data plans directly to consumers. If you have any questions about a specific eSIM or service, please contact the provider / seller directly.</p>
                    {/*CLIENT SIDE VALIDATION MESSAGES*/}
                    {errors.name && <div className="alert alert-danger alert-dismissible">
                        Please check the Name</div>}
                    {errors.email && <div className="alert alert-danger alert-dismissible">
                        Please check the Email</div>}
                    {errors.subject && <div className="alert alert-danger alert-dismissible">
                        Please check the Subject</div>}
                    {errors.message && <div className="alert alert-danger alert-dismissible">
                        Please check the Message</div>}

                    {/*SERVER SIDE VALIDATION MESSAGE*/}

                    {
                        (contactUs?.status === 0 && contactUs.message) &&
                        Object.keys(contactUs.message).map(key => <div className="alert alert-danger alert-dismissible">
                            {contactUs.message[key][0]}
                        </div>)
                    }

                    {/*SERVER SIDE SUCCESS MESSAGE*/}
                    {
                        contactUs?.status === 1 &&
                        <div className="alert alert-success alert-dismissible">
                            {contactUs?.message}
                        </div>
                    }


                    <Form method="post" onSubmit={handleSubmit(onSubmit)}>
                        <div className="get-from d-flex align-items-center justify-content-between mb-3">
                            <Form.Group className="getin-form mb-3 me-4" controlId="formBasicEmail">
                                <Form.Control type="Name" placeholder="Name"
                                    {...register("name", { required: true, min: 1})} />
                            </Form.Group>
                            <Form.Group className="getin-form mb-3 me-4" controlId="formBasicPassword">
                                <Form.Control type="Email" placeholder="Email"
                                    {...register("email", { required: true })} />
                            </Form.Group>
                            <Form.Group className="getin-form mb-3" controlId="formBasicPassword">
                                <Form.Control type="text" placeholder="Subject"
                                    {...register("subject", { required: true, min: 1})} />
                            </Form.Group>
                        </div>
                        <div className="contact-form mb-3">
                            <textarea id="getin-description" name="description" placeholder="Message" rows="6" cols="50"   {...register("message", { required: true, min: 1 })}></textarea>
                        </div>
                        <div className="text-end">
                            <Button className="orange-btn" type="submit">Submit</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>

    )
}
export default Getin;