import React from "react";

const PrivacyInfo = (props) => {
    
    return (
        <div className="privacy-info">
            
            <div className="container-width">
                <div dangerouslySetInnerHTML={{__html: props.description}} />
                
                {/* <div className="cookie-policy-wrapper mb-lg-5 mb-sm-3">
                    <div className="cookie-policy mb-lg-5 mb-3 d-lg-flex d-md-flex d-sm-flex align-items-center justify-content-between">
                        <div className="cookie-policy-content mb-lg-0 mb-md-0 mb-sm-0 mb-3">
                            <h4>Cookie Policy</h4>
                            <p className="mb-0">Anable Google analytics tracking:</p>
                        </div>
                        <Form>
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                            />
                        </Form>
                    </div>
                </div> */}
                
                <div dangerouslySetInnerHTML={{__html: props.description_2}} />
                
            </div>
            
        </div>
    )
}
export default PrivacyInfo;