import React from "react";

import { useSelector } from "react-redux";
const About = () => {  
    const settingData = useSelector((state) => state?.settings?.settingsData?.data?.settings)  
    return(
        <div className="about-home">
            <div className="container-width">
                <div className="about-content text-center">
                    <h2 className="position-relative">About <span className="orange">{"eSIMLIST"}</span></h2>
                    <p className="esim-description">{settingData?.esim_description}</p>
                </div>
            </div>
        </div>
    )
}
export default About;