import React from "react";

const Banner = () => {
    return (
        <div className="second-banner blog-banner">
            <div className="container-width">
                <div className="second-banner-content text-center">
                    <h1 ><span className="orange">BLOG</span></h1>
                    <p>Welcome to our blog, your ultimate guide to eSIM data plans worldwide! Discover the latest trends, tips, and insights on eSIM technology, from unlocking the potential of virtual SIMs to navigating the best internet data plans available in every corner of the globe. Stay connected seamlessly as we explore the ever-expanding world of eSIM together.</p>
                </div>
            </div>
        </div>
    )
}
export default Banner;