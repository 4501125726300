import { CMS_PAGES } from './cmsPagesTypes';

const initialState = {
   cmsPageList: [],
   success: false,
   loading: true,
   registered: false,
   error: false
}

const CmsPageReducer = (state = initialState, action) => {
    
   switch (action.type) {
      case CMS_PAGES:
         return {
            ...state,
            cmsPageList: action.payload,
            success: true,
            loading: false,
            registered: true
         }

      default:
         return state
   }
}

export default CmsPageReducer
