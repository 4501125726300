import { CMS_PAGES, ERROR_STATE } from "./cmsPagesTypes";
import { apiHelper } from "../apiHelper"
import 'react-toastify/dist/ReactToastify.css';

export const CmsPage = (slug) => async (dispatch) => {

  try {
    let res = await apiHelper("get", `cms-page/${slug}`, '')

    if (res?.data) {
      if (res?.data) {
        let { data } = res
        dispatch({
          type: CMS_PAGES,
          payload: data
        })

      }
      else {
        dispatch({
          type: ERROR_STATE
        })
      }
    }


  } catch (error) {
    console.log(error);
  }
}

