import React from "react";
import { useSelector } from "react-redux";
import { Image, Row, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import trileftimg from "../../../assets/images/tri-left.svg";
import trirightimg from "../../../assets/images/tri-right.svg";

const ProviderSlider = () => {
    const url = useParams()
    const bestPlans = useSelector((state) => state?.plans?.plansList?.best_plans)

    // var settings = {
    //     dots: false,
    //     infinite: false,
    //     speed: 500,
    //     slidesToShow: 3,
    //     slidesToScroll: 1,
    //     prevArrow: <FontAwesomeIcon icon={faAngleLeft} className="arrow-left" />,
    //     nextArrow: <FontAwesomeIcon icon={faAngleRight} className="arrow-right" />,
    //     responsive: [{

    //         breakpoint: 1400,
    //         settings: {
    //             slidesToShow: 3,
    //             infinite: true
    //         }

    //     }, {

    //         breakpoint: 991,
    //         settings: {
    //             slidesToShow: 2,
    //         }

    //     }, {

    //         breakpoint: 767,
    //         settings: {
    //             slidesToShow: 1,
    //         }

    //     }]
    // };
    return (
        <div className="plans-slider">
            <div className="container-width">
                <div className="plans-slider-wrapper">
                    <Row>
                        {
                            bestPlans ?
                                <>
                                    {bestPlans.mostWanted ?
                                        <Col lg="4" className="mb-lg-0 mb-4">
                                            <Link to={'/esim/' + url.slug + '/' + bestPlans.mostWanted?.providerSlug}>
                                                <div className="sr-item">
                                                    <div className="sr-item-content">
                                                        <div className="sr-item-top position-relative text-center">
                                                            <span>{bestPlans?.mostWanted?.heading}</span>
                                                            <div className="tri-left-img position-absolute">
                                                                <Image src={trileftimg} fluid />
                                                            </div>
                                                            <div className="tri-right-img position-absolute">
                                                                <Image src={trirightimg} fluid />
                                                            </div>
                                                        </div>
                                                        <div className="sr-item-content-info text-center">
                                                            <h2>{bestPlans?.mostWanted?.price}</h2>
                                                            <h6>{bestPlans?.mostWanted?.capacity} {bestPlans?.mostWanted?.capacityUnit}/ {bestPlans?.mostWanted?.duration}</h6>
                                                            <div className="sr-img-one d-flex justify-content-center">
                                                                <Image src={bestPlans?.mostWanted?.logo} fluid />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Col>
                                        : ""}
                                    {bestPlans.recommendedPlan ?
                                        <Col lg="4" className="mb-lg-0 mb-4">
                                            <Link to={'/esim/' + url.slug + '/' + bestPlans.recommendedPlan?.providerSlug}>
                                                <div className="sr-item">
                                                    <div className="sr-item-content">
                                                        <div className="sr-item-top position-relative text-center">
                                                            <span>{bestPlans?.recommendedPlan?.heading}</span>
                                                            <div className="tri-left-img position-absolute">
                                                                <Image src={trileftimg} fluid />
                                                            </div>
                                                            <div className="tri-right-img position-absolute">
                                                                <Image src={trirightimg} fluid />
                                                            </div>
                                                        </div>
                                                        <div className="sr-item-content-info text-center">
                                                            <h2>{bestPlans?.recommendedPlan?.price}</h2>
                                                            <h6>{bestPlans?.recommendedPlan?.capacity} {bestPlans?.recommendedPlan?.capacityUnit}/ {bestPlans?.recommendedPlan?.duration}</h6>
                                                            <div className="sr-img-one d-flex justify-content-center">
                                                                <Image src={bestPlans?.recommendedPlan?.logo} fluid />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Col>
                                        : ""}
                                    {bestPlans.bestDealPlan ?
                                        <Col lg="4" className="mb-lg-0 mb-4">
                                            <Link to={'/esim/' + url.slug + '/' + bestPlans.bestDealPlan?.providerSlug}>
                                                <div className="sr-item">
                                                    <div className="sr-item-content">
                                                        <div className="sr-item-top position-relative text-center">
                                                            <span>{bestPlans?.bestDealPlan?.heading}</span>
                                                            <div className="tri-left-img position-absolute">
                                                                <Image src={trileftimg} fluid />
                                                            </div>
                                                            <div className="tri-right-img position-absolute">
                                                                <Image src={trirightimg} fluid />
                                                            </div>
                                                        </div>
                                                        <div className="sr-item-content-info text-center">
                                                            <h2>{bestPlans?.bestDealPlan?.price}</h2>
                                                            <h6>{bestPlans?.bestDealPlan?.capacity} {bestPlans?.bestDealPlan?.capacityUnit}/ {bestPlans?.bestDealPlan?.duration}</h6>
                                                            <div className="sr-img-one d-flex justify-content-center">
                                                                <Image src={bestPlans?.bestDealPlan?.logo} fluid />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Col>
                                        : ""}

                                </>
                                : ""
                        }
                    </Row>
                </div>
            </div>
        </div>
    )
}
export default ProviderSlider;