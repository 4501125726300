import { PLANS, PAGINATE_PLANS } from './planTypes';
const initialState = {
   plansList: [],
   items: [],
   success: false,
   loading: true,
   registered: false,
   error: false,
   currentPage: 1,
   nextPage: 2,
   hasMore: true
}

const PlanReducer = (state = initialState, action) => {

   switch (action.type) {
      case PLANS:
         return {
            ...state,
            plansList: action.payload,
            items: action.payload.data,
            currentPage: 1,
            success: true,
            loading: false,
            registered: true
         }
      case PAGINATE_PLANS:
         return {
            ...state,
            plansList: action.payload,
            items: [...state.items, ...action.payload.data],
            nextPage: state.nextPage+ 1,
            hasMore: action.payload.meta.to ? true : false,
            success: true,
            loading: false,
            registered: true
         }
      default:
         return state
   }
}

export default PlanReducer
