import React from "react";
import { Image } from "react-bootstrap";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

const PlanBuy = () => {
  const providerPlans = useSelector((state) => state?.providerPlans?.providerPlansList?.data)
  const how_to_buy = providerPlans?.provider_details?.howToBuy

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <FontAwesomeIcon icon={faChevronLeft} />,
    nextArrow: <FontAwesomeIcon icon={faChevronRight} />,


    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
         
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
         
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    how_to_buy?.length > 0 ?
    <div className="plan-buy">
      
      <div className="plan-buy-heading text-center">
        <h4 className="orange mb-lg-5 mb-4">HOW TO BUY</h4>
      </div>
      <div className="container-width">
          <Slider className="pro-slider" {...settings}>
            {how_to_buy?.map((item, index) => (
              <>
                <div className="plan-buy-box-content green">
                  <div className="slider-img mb-4">
                    <Image src={item.image} fluid />
                  </div>

                  <div className="plan-buy-box-content-bottom border-green text-center">
                    <h6>{item.title}</h6>
                    <p>
                      {item.description}
                    </p>
                  </div>
                </div>

              </>
            ))
            }
          </Slider>
      </div>
      
    </div>
    : ""
  );
};
export default PlanBuy;
