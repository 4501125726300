import React, { useEffect } from "react";
import Footer from "../Home/Footer/Footer";
import Header from "../Home/Header/Header";
import CountryListInfo from "./CountryListInfo/CountryListInfo";

const CountryList = () => {
    // Scroll to Top on page Load
    useEffect(() => {
    window.scrollTo(0, 0)
    }, [])
    return(
        <div className="wrapper">
            <Header/>
            <CountryListInfo/>
            <Footer/>
        </div>
    )
}
export default CountryList;