import React, { useEffect } from "react";
import Footer from "../Home/Footer/Footer";
import Header from "../Home/Header/Header";
import RegionsListInfo from "./RegionsListInfo/RegionsListInfo";

const RegionsList = () => {
    // Scroll to Top on page Load
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return(
        <div className="wrapper">
            <Header/>
            <RegionsListInfo/>
            <Footer/>
        </div>
    )
}
export default RegionsList;