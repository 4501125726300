import React, { useState, useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown, Image, InputGroup, Form } from 'react-bootstrap'
import logo from '../../../assets/images/logo.svg'
import { faDollar } from '@fortawesome/free-solid-svg-icons'
import clip from '../../../assets/images/clip-top.png'
import top from '../../../assets/images/clip-top-color.png'
import headerSearch from '../../../assets/images/banner-search-header.svg'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { SearchRegionAndCountries } from '../../../redux/searchRegionAndCountries/searchRegionAndCountriesActions'
import { Link } from 'react-router-dom'
const Header = (props) => {
  const dispatch = useDispatch()
  const url = useParams();
  const inputRef = useRef(null)
  var link = window.location.href.split('/')

  const LoadingProviders = useSelector((state) => state?.providerPlans?.loading)
  const LoadingPlans = useSelector((state) => state?.plans?.loading)

  const [ShowContent, setShowContent] = useState(true);
  const [ShowSearch, setShowSearch] = useState(false);

  // dispatch All Region and Countries
  useEffect(() => {
    dispatch(SearchRegionAndCountries())
  }, [dispatch])

  const options = useSelector((state) => state?.countriesAndRegion?.searchRegionAndCountriesList?.data)
  // Fetch Country Details
  const plans = useSelector((state) => state?.plans?.plansList?.area);

  const Loading = plans == null ? LoadingProviders : LoadingPlans;

  const checkOption = options?.find((item) => item.slug === link[4])
  
  useEffect(() => {
    setShowContent(true)
    setShowSearch(false)
  }, [url])
  // hide & show search tabs
  const HandleSearchTab = () => {
    setShowContent(false)   
    setShowSearch(true)   
  }

  // focus input field of search
  useEffect(() => {
    inputRef?.current?.focus();
  },[ShowContent]);

  // onclick outside show country on search
  const concernedElement = document.querySelector(".click-text");
  useEffect(() => {
    document.addEventListener("mousedown", (event) => {
      if (!(concernedElement?.contains(event.target))) {
        handleClickOutside()
      }
    });
  });
  const handleClickOutside = e => {
    setShowContent(true)
    setShowSearch(false)
  };
  return (
    <header className="header">
      <div className="container-width">
        <div className="header-content d-flex align-items-center justify-content-between">
          <Link to="/">
            <div className="logo mb-lg-0 mb-0">
              <Image src={logo} fluid />
            </div>
          </Link>
          {(link[3] === 'esim') && (
            <div className="header-search-select desktop-view position-relative click-text">
              {
                ShowSearch === true &&
                <div>
                  <div className='search-img'>
                  <Image className='img-fluid' src={headerSearch} />
                  </div>
              
                <Select classNamePrefix="header-custom"
                  ref={inputRef}
                  placeholder="Select Country or Region"
                  options={options}
                  onChange={props.handleClick}
                  formatOptionLabel={(country) => (
                    <div className="country-search-header d-flex align-items-center justify-content-between">
                      <div className="country-option d-flex align-items-center ">
                        <Image src={country.image} />
                        <span>{country.label}</span>
                      </div>
                      
                    </div>
                  )}
                />
                </div>
              }
              {
                Loading === true ?
                <div className="header-country-search d-flex align-items-center" onClick={HandleSearchTab} >
                  <InputGroup className="header-search-form mb-0 d-flex align-items-center">
                    <div className='header-flag-img me-2'>
                    <Image className='img-fluid' src={checkOption?.image} />
                    </div>
                  
                    <Form.Control
                      placeholder='Loading....'
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      disabled
                    />
                  </InputGroup>
                </div>
                :
                ShowContent === true &&
                <div className="header-country-search d-flex align-items-center" onClick={HandleSearchTab} >
                  <InputGroup className="header-search-form mb-0 d-flex align-items-center">
                    <div className='header-flag-img me-2'>
                    <Image src={checkOption?.image} />
                    </div>
                   
                    <Form.Control
                      value={checkOption?.label}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      disabled
                    />
                    <span>Change</span>
                  </InputGroup>
                </div>
              }
            </div>
          )}
          <div className="web-dd">
            <div className="dropdowns-wrapper d-flex align-items-center">
              <Dropdown className="me-2">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="d-flex align-items-center drop-curr-wrapper "
                >
                  <div className="drop-curr d-flex align-items-center">
                    <FontAwesomeIcon
                      icon={faDollar}
                      className="dollar-icon me-2"
                    />
                    <h6 className="mb-0">USD</h6>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="drop-curr-header">
                  <div className="dropdwon-top-clip-img">
                    <Image src={clip} />
                  </div>
                  <Dropdown.Item href="#/action-1">
                    <div className="drop-curr d-flex align-items-center">
                      <FontAwesomeIcon
                        icon={faDollar}
                        className="dollar-icon me-2"
                      />
                      <h6 className="mb-0">USD</h6>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="d-flex align-items-center drop-language-wrapper"
                >
                  <h6 className="mb-0">English</h6>
                </Dropdown.Toggle>

                <Dropdown.Menu className="header-btn-color">
                  <div className="dropdwon-top-clip-img">
                    <Image src={top} />
                  </div>
                  <Dropdown.Item href="#/action-1">
                    <h6 className="mb-0">English</h6>
                  </Dropdown.Item>
                  {/* <Dropdown.Item href="#/action-2">
                    <h6 className="mb-0">English</h6>
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-3">
                    <h6 className="mb-0">English</h6>
                  </Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="mobile-dd ">
            <div className="dropdowns-wrapper d-flex align-items-center">
              <Dropdown className="me-2">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="d-flex align-items-center drop-curr-wrapper"
                >
                  <div className="drop-curr d-flex align-items-center">
                    <FontAwesomeIcon
                      icon={faDollar}
                      className="dollar-icon me-2"
                    />
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">
                    <div className="drop-curr-header d-flex align-items-center">
                      <div className="dropdwon-top-clip-img">
                        <Image src={clip} />
                      </div>
                      <FontAwesomeIcon
                        icon={faDollar}
                        className="dollar-icon me-2"
                      />
                      <h6 className="mb-0">USD</h6>
                    </div>
                  </Dropdown.Item>
                  {/* <Dropdown.Item href="#/action-2">
                    <div className="drop-curr d-flex align-items-center">
                      <FontAwesomeIcon
                        icon={faDollar}
                        className="dollar-icon me-2"
                      />
                      <h6 className="mb-0">USD</h6>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-3">
                    <div className="drop-curr d-flex align-items-center">
                      <FontAwesomeIcon
                        icon={faDollar}
                        className="dollar-icon me-2"
                      />
                      <h6 className="mb-0">USD</h6>
                    </div>
                  </Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="d-flex align-items-center drop-language-wrapper"
                >
                  <h6 className="mb-0">En</h6>
                </Dropdown.Toggle>

                <Dropdown.Menu className="header-btn-color">
                  <div className="dropdwon-top-clip-img">
                    <Image src={top} />
                  </div>
                  <Dropdown.Item href="#/action-1">
                    {' '}
                    <h6 className="mb-0">English</h6>
                  </Dropdown.Item>
                  {/* <Dropdown.Item href="#/action-2">
                    <h6 className="mb-0">English</h6>
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-3">
                    <h6 className="mb-0">English</h6>
                  </Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
         {(link[3] === 'provider' || link[3] === 'plans') && (
         <div className="header-search-select mobie-view position-relative click-text">
              {
                ShowSearch === true &&
                <div>
                  <div className='search-img'>
                  <Image className='img-fluid' src={headerSearch} />
                  </div>
              
                <Select classNamePrefix="header-custom"
                  // {...checkOption}
                  // value={{
                  //   label: checkOption?.label,
                  //   value: checkOption?.value,
                  //   image: checkOption?.image,
                  // }}
                  placeholder="Search by country name"
                  options={options}
                  onChange={props.handleClick}
                  formatOptionLabel={(country) => (
                    <div className="country-search-header d-flex align-items-center justify-content-between">
                      <div className="country-option d-flex align-items-center ">
                        <Image src={country.image} />
                        <span>{country.label}</span>
                      </div>
                      
                    </div>
                  )}
                />
                </div>
              }
              {
                ShowContent === true &&
                <div className="header-country-search d-flex align-items-center" onClick={HandleSearchTab}>
                  <InputGroup className="header-search-form mb-0 d-flex align-items-center">
                    <div className='header-flag-img me-2'>
                    <Image className='img-fluid' src={checkOption?.image} />
                    </div>
                  
                    <Form.Control
                      value={checkOption?.label}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      disabled
                    />
                    <span>Change</span>
                  </InputGroup>
                </div>
              }
            </div>
          )}
      </div>
    </header>
  )
}
export default Header
