import { SETTINGS, ERROR_STATE } from "./settingTypes";
import { apiHelper } from "../apiHelper"

export const Settings = () => async (dispatch) => {
  try {
    let res = await apiHelper("get", `settings`, '')

    if (res?.data) {
      if (res?.data) {
        let { data } = res
        dispatch({
          type: SETTINGS,
          payload: data
        })

      }
      else {
        dispatch({
          type: ERROR_STATE
        })
      }
    };


  } catch (error) {
    console.log(error);
  }
}


