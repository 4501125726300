import React from "react";
import {Row,Col,Image} from "react-bootstrap";
import helpfone from "../../../assets/images/helpfone.png";


const Helpdesk =(props)=>{
    return(
        <>
        {/* <div className="top-support-img">
            <Image src={plane}  />
          </div> */}
         
        <section className="help-desk">
            <div className="container-width">
                <Row className="align-items-center">
                    <Col lg="7">
                        <div className="helpdesk">
                            <h2><span className="help-desk-color">24/7 help desk</span></h2>
                            <p><div dangerouslySetInnerHTML={{__html: props.description_2}} /></p>
                        </div>
                    </Col>
                    <Col lg="5">
                        <div className="helpdesk-img">
                            <Image src={helpfone} />
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
        </>
      

    )
}
export default Helpdesk;