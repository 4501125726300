import React from "react";
import {  useSelector } from "react-redux";
import { Spinner } from "../../Common/Spinner/Spinner";

const Banner = () => {
    const Loading = useSelector((state) => state?.plans?.loading)
    const title = useSelector((state) => state?.plans?.plansList?.header_title)
    const description = useSelector((state) => state?.plans?.plansList?.header_description)
    return(

        <div className="second-banner plans-banner banner-top">
            <div className="container-width">
                {
                Loading ? <Spinner /> :
                    <div className="second-banner-content text-center position-relative">
                        <h1 ><span className="orange">{title}</span></h1>
                        <p>{description}</p>
                    </div>
                }
            </div>
        </div>
    )
}
export default Banner;