import { REGION_COUNTRIES } from './regionCountriesTypes';

const initialState = {
   regionsCountriesList: [],
   success: false,
   loading:true,
   registered: false,
   error: false
}

const regionsCountriesReducer = (state = initialState, action) => {
   
   switch (action.type) {
      case REGION_COUNTRIES:
         return {
            ...state,
            regionsCountriesList: action.payload,
            success: true,
            loading:false,
            registered: true,
            
         }

      default:
         return state
   }
}

export default regionsCountriesReducer
