import React from "react";
import { useSelector } from "react-redux";
import facebooklogo from "../../../assets/images/facebook.svg";
import twitterlogo from "../../../assets/images/twitter.svg";
import linkdinlogo from "../../../assets/images/linkdin.svg";
import pinterestlogo from "../../../assets/images/pinterest.svg";
import youtubelogo from "../../../assets/images/youtube.svg";
import { Image } from "react-bootstrap";


const Banner = () => {
    const settingData = useSelector((state) => state?.settings?.settingsData?.data?.settings)
    return (
        <div className="second-banner contactus-banner">
            <div className="container-width">
                <div className="contact-us-banner second-banner-content text-center">
                    <h1 ><span className="orange">CONTACT US</span></h1>
                    <p>aaEtiam facilisis ligula nec velit posuere egestas. Nunc dictum lectus sem, vel dignissim purus luctus quis. Vestibulum et ligula suscipit, hendrerit erat a.</p>
                </div>
                <div className="footer-social mb-0 d-flex align-items-center justify-content-center">
                    {settingData?.facebook &&
                        <a href={settingData.facebook} target="_blank" rel="noreferrer">
                            <div className="social-icons-img-banner d-flex align-items-center justify-content-center">
                                <Image src={facebooklogo} fluid />
                            </div>
                        </a>
                    }
                    {settingData?.twitter &&
                        <a href={settingData.twitter} target="_blank" rel="noreferrer">
                            <div className="social-icons-img-banner d-flex align-items-center justify-content-center">
                                <Image src={twitterlogo} fluid />
                            </div>
                        </a>
                    }
                    {settingData?.linkedin &&
                        <a href={settingData.linkedin} target="_blank" rel="noreferrer">
                            <div className="social-icons-img-banner d-flex align-items-center justify-content-center">
                                <Image src={linkdinlogo} fluid />
                            </div>
                        </a>
                    }
                    {settingData?.pinterest &&
                        <a href={settingData.pinterest} target="_blank" rel="noreferrer">
                            <div className="social-icons-img-banner d-flex align-items-center justify-content-center">
                                <Image src={pinterestlogo} fluid />
                            </div>
                        </a>
                    }
                    {settingData?.youtube &&
                        <a href={settingData.youtube} target="_blank" rel="noreferrer">
                            <div className="social-icons-img-banner d-flex align-items-center justify-content-center">
                                <Image src={youtubelogo} fluid />
                            </div>
                        </a>
                    }
                </div>
            </div>
        </div>
    )
}
export default Banner;