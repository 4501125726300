import React, { useEffect } from "react";
import Header from "../Home/Header/Header";
import Banner from "../Support/Banner/Banner";
import Helpdesk from "../Support/Helpdesk/Helpdesk";
import Footer from "../Home/Footer/Footer";
import OurHelp from "./OurHelp/OurHelp";
import { ToastContainer } from 'react-toastify';
import { CmsPage } from '../../redux/cmsPages/cmsPagesActions';
import { useDispatch, useSelector } from "react-redux";
const Support =()=>{
    const dispatch = useDispatch();
    var location = window.location.href.split('/');
    let slug = location[3];
    // Scroll to Top on page Load
    useEffect(() => {
    window.scrollTo(0, 0)
    }, [])

    // Get Cms Pages by Slug
    useEffect(() => {
        dispatch(CmsPage(slug));
    }, [dispatch,slug]);
    
    const faq = useSelector((state) => state?.cmsPages?.cmsPageList?.data?.faq);
    const description_2 = useSelector((state) => state?.cmsPages?.cmsPageList?.data?.cms?.description_2);
    const description = useSelector((state) => state?.cmsPages?.cmsPageList?.data?.cms?.description);
    return(
        <div className="wrapper">
            <ToastContainer/>
            <Header/>
            <Banner description={description}/>
            <Helpdesk description_2={description_2}/>
            <OurHelp faq={faq}/>
            <Footer/>
        </div>
    )
}
export default Support;