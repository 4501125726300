import React, { useEffect } from "react";
import { Link, useParams } from 'react-router-dom'
import { Col, Row, Image } from "react-bootstrap";
import { RegionCountries } from '../../../redux/regionCountries/regionCountriesActions';
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "../../Common/Spinner/Spinner";

const CountryListInfo = () => {
    const dispatch = useDispatch();
    const region_id = useParams();

    useEffect(() => {
        dispatch(RegionCountries(region_id.slug));
    }, [dispatch,region_id.slug]);
    const header_title = useSelector((state) => state?.regionCountries?.regionsCountriesList?.header_title);
    const header_description = useSelector((state) => state?.regionCountries?.regionsCountriesList?.header_description);
    const regionCountries = useSelector((state) => state?.regionCountries?.regionsCountriesList?.data);
     const Loading = useSelector((state) => state?.regionCountries?.loading)
    return (
        <div className="about">
           
            {Loading ? <Spinner /> :
                <>
                    <div className="container-width">
                        <div className="about-content text-center mb-5">
                            <h2 className="position-relative orange">{header_title}</h2>
                            <p>{header_description}</p>
                        </div>
                        <div className="about-boxes">
                            <Row>
                                <Col lg="12">
                                    <Row>
                                        {
                                            regionCountries?.length > 0 &&
                                            regionCountries?.map((item, index) => (
                                                <Col lg="6" className="mb-3">
                                                    <Link to={`/esim/${item.country_slug}`}>
                                                        <div className="about-country-flags d-flex align-items-center justify-content-between">
                                                            <p>{item.name}</p>
                                                            <div className="about-country-flags-img about-region-flag">
                                                                <Image src={item.flag_image} fluid />
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}
export default CountryListInfo;