import { FETCH_REQUEST,FETCH_POSTS, FETCH_POST, FETCH_CATEGORIES } from "./blogPostTypes";

const initialState = {
    posts : [],
    categories:[],
    success: false,
    loading:false,
    registered: false,
    error: false
};

const blogPostReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case FETCH_REQUEST:
        return {
            ...state,
            loading: true,
            error: null
        };
        case FETCH_POSTS:
          return {
             ...state,
             posts: action.payload,
             success: true,
             loading: false,
             registered: true
          }
        case FETCH_CATEGORIES:
        return {
            ...state,
            categories: action.payload,
            success: true,
            loading: false,
            registered: true
        }
        case FETCH_POST:
            return {
               ...state,
               post: action.payload,
               success: true,
               loading: false,
               registered: true
            }
       default:
          return state
    }
 }
 
 export default blogPostReducer 
