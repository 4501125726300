import { SEARCH_REGION_AND_COUNTRIES, ERROR_STATE } from "./searchRegionAndCountriesTypes";
import { apiHelper } from "../apiHelper"

export const SearchRegionAndCountries = () => async (dispatch) => {
  try {
    let res = await apiHelper("get", `search-countries-and-regions`, '')
    
    if (res?.data) {
      if (res?.data) {
        let { data } = res
        dispatch({
          type: SEARCH_REGION_AND_COUNTRIES,
          payload: data
        })

      }
      else {
        dispatch({
          type: ERROR_STATE
        })
      }
    };


  } catch (error) {
    console.log(error);
  }
}


