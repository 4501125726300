import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom'
import { ProviderPlans } from '../../../redux/providerPlans/providerPlansActions';
import { Button, Col, Row, Modal, Image } from 'react-bootstrap'
import { Spinner } from "../../Common/Spinner/Spinner";

const PlansDataBoxes = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false)
  /**SET COVERAGES IN MODEL**/
  const [coverages, setcoverages] = useState("")



  const url = useParams();
  useEffect(() => {
    dispatch(ProviderPlans(url.slug,url.provider));
  }, [dispatch,url.slug,url.provider]);

  const loading = useSelector((state) => state?.providerPlans?.loading)
  const providerPlans = useSelector((state) => state?.providerPlans?.providerPlansList?.data)
  const singleCountryPlans = providerPlans?.single_country_plans
  const multiCountryPlans = providerPlans?.multiple_countries_plans
  const isCountryPlan = providerPlans?.is_country_providers
  const countryDetail = providerPlans?.country_details
  const regionDetail = providerPlans?.region_details

  const handleClose = () => setShow(false)
 
  const handleShow = (data) => {
    setShow(true)
    setcoverages(data)
  }
  return (
    <>
      <div className="mogo-data-boxes">
        <div className="mogo-data-boxes-heading text-center">
          <h4 className="orange mb-lg-4 mb-md-4 mb-sm-3 text-uppercase">
            eSIM DATA PLANS FOR {isCountryPlan === 1 ? countryDetail?.name : regionDetail?.name}
          </h4>
        </div>

        {
          loading ? <Spinner /> :
            singleCountryPlans?.length > 0 ?
              <div className="container-width">
                <span className="mogo-data-boxes-content-heading">
                  Single Country plans
                </span>
                <Row className="mb-lg-5 mb-md-4 mb-sm-3 mb-3">

                  {

                    singleCountryPlans?.map((item, index) => (
                      <>
                        <Col lg="4" className='mb-lg-5 mb-md-5 mb-sm-4 mb-3'>
                          <div className="mogo-data-boxes-content position-relative">
                            <h6>{item.planTitle}</h6>
                            <p className="d-flex align-items-center">
                              <span className="orange">{item.capacity} {item.capacityUnit}</span>For {item.duration}
                            </p>
                            <div className="mogo-data-boxes-content-info mb-4">
                              <a href={() => false} onClick={() => { handleShow(item.coverages) }} className="d-inline-flex align-items-center">
                                <p className="mb-0 me-1">
                                  eSim plan details
                                </p>
                                <FontAwesomeIcon
                                  icon={faExclamationCircle}
                                  className="exclamation-icon"
                                />
                              </a>
                            </div>
                            <a href={item.url} target="_blank" rel="noopener noreferrer" className="btn-transparent">Buy Now</a>
                            {/* <Button className="btn-transparent">Buy Now</Button> */}
                            <div className="mogo-data-boxes-price position-absolute">
                              <span>{item.price}</span>
                            </div>
                          </div>
                        </Col>
                      </>
                    ))
                  }

                </Row>
              </div>
              : ""}
        {
          multiCountryPlans?.length > 0 ?
            <div className="container-width">
              <span className="mogo-data-boxes-content-heading">
                Multiple Country plans
              </span>
              <Row className="mb-lg-5 mb-md-4 mb-sm-3 mb-3">

                {

                  multiCountryPlans?.map((item, index) => (
                    <>
                      <Col lg="4" className='mb-lg-5 mb-md-5 mb-sm-4 mb-3'>
                        <div className="mogo-data-boxes-content position-relative">
                          <h6>{item.planTitle}</h6>
                          <p className="d-flex align-items-center">
                            <span className="orange">{item.capacity} {item.capacityUnit}</span>For {item.duration}
                          </p>
                          <div className="mogo-data-boxes-content-info mb-4">
                            <a href={() => false} onClick={() => { handleShow(item.coverages) }} className="d-inline-flex align-items-center">
                              <p className="mb-0 me-1">
                                eSim plan details
                              </p>
                              <FontAwesomeIcon
                                icon={faExclamationCircle}
                                className="exclamation-icon"
                              />
                            </a>
                          </div>
                          <a href={item.url} target="_blank" rel="noopener noreferrer" className="btn-transparent">Buy Now</a>
                          {/* <Button className="btn-transparent">Buy Now</Button> */}
                          <div className="mogo-data-boxes-price position-absolute">
                            <span>{item.price}</span>
                          </div>
                        </div>
                      </Col>
                    </>
                  ))
                }

              </Row>
            </div>
            : ""}
      </div>
      <Modal className='popups' show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Plan detail</Modal.Title>
        </Modal.Header>
        <div className='popups-content'>
          <Modal.Body className='popups-p p-0'>
            <p className='ps-1'>Countries covered by this plan</p>
          </Modal.Body>
          {
            coverages?.length > 0 ?
              coverages?.map((item, index) => (
                <div className='d-flex align-items-center'>
                  <div className='about-country-flags-img-popups m-0'>
                    <Image src={item.flag_image} />
                  </div>
                  <p className='mb-0 ms-3'>{item.name}</p>
                </div>
              ))
              : <Spinner />}

        </div>
        <Modal.Footer>
          <Button className='btn-transparent-popup' onClick={handleClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default PlansDataBoxes
