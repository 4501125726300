import { REGION_COUNTRIES, ERROR_STATE } from "./regionCountriesTypes";
import { apiHelper } from "../apiHelper"

export const RegionCountries = (slug) => async (dispatch) => {

  try {
    let res = await apiHelper("get", `fetch-region-countries?region=${slug}`, '')

    if (res?.data) {
      if (res?.data) {
        let { data } = res
        dispatch({
          type: REGION_COUNTRIES,
          payload: data
        })

      }
      else {
        dispatch({
          type: ERROR_STATE
        })
      }
    }


  } catch (error) {
    console.log(error);
  }
}


