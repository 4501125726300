import React, { useEffect } from "react";
import Footer from "../Home/Footer/Footer";
import Header from "../Home/Header/Header";
import PageNotFoundInfo from "./PageNotFoundInfo/PageNotFoundInfo";
const Privacy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="wrapper">
            <Header />
            <PageNotFoundInfo />
            <Footer />
        </div>
    )
}
export default Privacy;