import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import mark from "../../../assets/images/banner-mark.svg"

const Banner = (props) => {
    return (
        <div className="support-banner second-banner">
            <div className="container-width">
                <Row className="align-items-center">
                    <Col lg="6">
                        <div className="support-banner-content text-lg-start text-center">
                            <h1><span className="orange">SUPPORT</span></h1>
                            <p><div dangerouslySetInnerHTML={{ __html: props.description }} /></p>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="support-banner-mark">
                            <Image src={mark} />
                        </div>
                    </Col>
                </Row>
            </div>
        </div>

    )
}
export default Banner;