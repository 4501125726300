import React, { useEffect } from "react";
import Footer from "../Home/Footer/Footer";
import Header from "../Home/Header/Header";
import Banner from "./Banner/Banner";
import PrivacyInfo from "./PrivacyInfo/PrivacyInfo";
import { CmsPage } from '../../redux/cmsPages/cmsPagesActions';
import { useDispatch, useSelector } from "react-redux";
const Privacy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        }, [])
        const dispatch = useDispatch();
        var location = window.location.href.split('/');
        let slug = location[3];
        useEffect(() => {
            dispatch(CmsPage(slug));
        }, [dispatch,slug]);
        
        const description = useSelector((state) => state?.cmsPages?.cmsPageList?.data?.cms?.description);
        const description_2 = useSelector((state) => state?.cmsPages?.cmsPageList?.data?.cms?.description_2);

    return(
        <div className="wrapper">
            <Header/>
            <Banner/>
            <PrivacyInfo description={description} description_2={description_2}/>
            <Footer/>
        </div>
    )
}
export default Privacy;