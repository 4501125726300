import React from "react";

const Banner = () => {
    return (
        <div className="second-banner privacy-banner">
            <div className="container-width">
                <div className="second-banner-content text-center">
                    <h1 ><span className="orange">PRIVACY</span></h1>
                    <p>Proin ex ipsum, facilisis id tincidunt sed, vulputate in lacus. Donec pharetra faucibus leo, vitae</p>
                </div>
            </div>
        </div>
    )
}
export default Banner;