import React from "react";
import { Spinner } from "../../Common/Spinner/Spinner";
import { useNavigate } from 'react-router-dom';

const PostDetail = (props) => {
    const navigate = useNavigate();

    return (
        <div className="blog-info">
            {props?.loading ? (
                <Spinner />
            ) : (
                <div className="container-width">
                    <button onClick={() => navigate(-1)} className="back-button">
                        <span>Back</span>
                    </button>
                    <div className="content">
                        <div dangerouslySetInnerHTML={{__html: props.description}} />  
                    </div>
                </div>
            )}
        </div>
    );
}

export default PostDetail;
