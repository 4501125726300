import React, { useEffect } from "react";
import Footer from "../Home/Footer/Footer";
import Header from "../Home/Header/Header";
import Banner from "./Banner/Banner";
import TermsInfo from "./TermsInfo/TermsInfo";
import { CmsPage } from '../../redux/cmsPages/cmsPagesActions';
import { useDispatch, useSelector } from "react-redux";
const Terms = () => {
    const dispatch = useDispatch();
    var location = window.location.href.split('/');
    let slug = location[3];
    // Scroll to Top on page Load
    useEffect(() => {
    window.scrollTo(0, 0)
    }, [])
    
    // Get Cms Pages by Slug
    useEffect(() => {
        dispatch(CmsPage(slug));
    }, [dispatch,slug]);
    const description = useSelector((state) => state?.cmsPages?.cmsPageList?.data?.cms?.description);
    const description_2 = useSelector((state) => state?.cmsPages?.cmsPageList?.data?.cms?.description_2);
    return(
        <div className="wrapper">
            <Header/>
            <Banner description_2={description_2}/>
            <TermsInfo description={description}/>
            <Footer/>
        </div>
    )
}
export default Terms;