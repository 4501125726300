import { OUR_HELP, ERROR_STATE } from "./ourHelpTypes";
import { apiHelper } from "../apiHelper"
import 'react-toastify/dist/ReactToastify.css';

export const HelpDesk= (formData) => async (dispatch) => {
  try {
    let res = await apiHelper("post", `contact-us`, formData)
    
    if (res?.data) {
      if (res?.data) {
        let { data } = res
        dispatch({
          type: OUR_HELP,
          payload: data
        })

      }
      else {
        dispatch({
          type: ERROR_STATE
        })
      }
    };


  } catch (error) {
    console.log(error);
  }
}


