import { useEffect } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import 'react-select-search/style.css';
import Home from './Components/Home/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Provider from './Components/Provider/Provider';
import Privacy from "./Components/Privacy/Privacy";
import RegionsList from "./Components/RegionsList/RegionsList";
import Plans from "./Components/Plans/Plans";
import CountryList from "./Components/CountryList/CountryList";
import Terms from "./Components/Terms/Terms";
import Support from "./Components/Support/Support";
import Contactus from "./Components/Contactus/Contactus";
import Blog from "./Components/Blog/Blog";
import Post from "./Components/Post/Post";
import { useSelector, useDispatch  } from "react-redux";
import {Helmet} from "react-helmet";
import PageNotFound from './Components/PageNotFound/PageNotFound';
import { ProviderPlans } from './redux/providerPlans/providerPlansActions';
import { fetchPost } from './redux/blogPost/blogPostActions';

function App() {
	const settingData = useSelector((state) => state?.settings?.settingsData?.data?.settings)
	const plans = useSelector((state) => state?.plans?.plansList?.area)
	const providerPlans = useSelector((state) => state?.providerPlans?.providerPlansList?.data)
	const { postList } = useSelector((state) => state);
	const post = postList?.post?.data;
	var link = window.location.href.split('/');
	let slug = link[4];
	const blogMetaValue = {
        title: 'Blog - eSim',
        meta_title: 'Latest Blog Posts - eSim',
		meta_keyword: 'blog, articles, posts, news, updates',
		meta_description: 'Explore our latest blog posts for insightful articles, news, and updates on eSim. Stay informed and entertained with our diverse range of content.'
    }
	const dispatch = useDispatch();
	// Fetch data when the component mounts
	useEffect(() => {
		dispatch(ProviderPlans());
		dispatch(fetchPost(slug));
	}, [dispatch, slug]);

	// Listen to popstate event
	useEffect(() => {
		const handlePopstate = () => {
			// Dispatch action to fetch updated data
			dispatch(ProviderPlans());
			dispatch(fetchPost(slug));
		};

		window.addEventListener('popstate', handlePopstate);

		return () => {
			window.removeEventListener('popstate', handlePopstate);
		};
	}, [dispatch, slug]);
	// const option = link[3] === 'esim' ? (plans === null ? (providerPlans?.country_details === '' ? providerPlans?.region_details : providerPlans?.country_details): plans) : settingData
	let option;
	switch(true) {
		case link[3] === 'esim' && plans === null && providerPlans?.country_details === '':
			option = providerPlans?.region_details;
			break;
		case link[3] === 'esim' && plans === null && providerPlans?.country_details !== '':
			option = providerPlans?.country_details;
			break;
		case link[3] === 'esim' && plans !== null:
			option = plans;
			break;
		case link[3] === 'blog' && post?.length === 0:
		    option = blogMetaValue;
		    break;
		case link[3] === 'blog' && post !== null:
			option = post;
			break;
		default:
			option = settingData;
	}
	const blogTitle = providerPlans ? `${providerPlans?.provider_details?.meta_title} ${option?.name}` : option?.title;
	const title = providerPlans ? `${providerPlans?.provider_details?.meta_title} ${option?.name}` : option?.meta_title;
	return (
		<div className="App">
			<Helmet>
				<title>{blogTitle ? blogTitle : title}</title>
				<meta name="title" content={option?.meta_title} />
				<meta name="keyword" content={option?.meta_keyword} />
				<meta name="description" content={option?.meta_description} />
				<link rel="canonical" href={window.location.href}></link>
				<meta property="og:url" content={window.location.href}></meta>
			</Helmet>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/esim/:slug" element={<Provider />}   />
					<Route path="/privacy-policy" element={<Privacy />} />
					<Route path="/esim/:slug/:provider" element={<Plans />} />
					<Route path="/regions" element={<RegionsList />} />
					<Route path="/region/:slug" element={<CountryList />} />
					<Route path="/terms-and-conditions" element={<Terms />} />
					<Route path="/support" element={<Support />} />
					<Route path="/contact-us" element={<Contactus />} />
					<Route path="/blog" element={<Blog />} />
					<Route path="/blog/:slug" element={<Post />} />
					<Route path="*" exact={true} element={<PageNotFound />} />
					
				</Routes>
				
			</BrowserRouter>
		</div>
	);
}

export default App;
