import { PLANS, PAGINATE_PLANS, ERROR_STATE } from './planTypes';
import { apiHelper } from "../apiHelper"
import 'react-toastify/dist/ReactToastify.css';

export const Plans = (slug, gbrange, dayrange, pricerange, orderBy, page) => async (dispatch) => {

  try {

    
    const bodyParameters = {
      order_by:orderBy,
      min_capacity: gbrange ? gbrange[0] : "",
      max_capacity: gbrange ? gbrange[1] : "",
      min_duration: dayrange ? dayrange[0] : "",
      max_duration: dayrange ? dayrange[1] : "",
      min_price: pricerange ? pricerange[0] : "",
      max_price: pricerange ? pricerange[1] : "",
      page: page,
    };
    bodyParameters['slug'] = slug;
    
    let res = await apiHelper("post", `fetch-plans`, bodyParameters)
  
    if (res?.data) {
      if (res?.data) {
        let { data } = res
        dispatch({
          type: PLANS,
          payload: data
        })

      }
      else {
        dispatch({
          type: ERROR_STATE
        })
      }
    }


  } catch (error) {
    console.log(error);
  }
}

export const PaginatePlans = (slug, gbrange, dayrange, pricerange, orderBy, page) => async (dispatch) => {

  try {

    
    const bodyParameters = {
      order_by:orderBy,
      min_capacity: gbrange ? gbrange[0] : "",
      max_capacity: gbrange ? gbrange[1] : "",
      min_duration: dayrange ? dayrange[0] : "",
      max_duration: dayrange ? dayrange[1] : "",
      min_price: pricerange ? pricerange[0] : "",
      max_price: pricerange ? pricerange[1] : "",
      page: page,
    };
    bodyParameters['slug'] = slug;
    
    let res = await apiHelper("post", `fetch-plans`, bodyParameters)
  
    if (res?.data) {
      if (res?.data) {
        let { data } = res
        dispatch({
          type: PAGINATE_PLANS,
          payload: data
        })

      }
      else {
        dispatch({
          type: ERROR_STATE
        })
      }
    }


  } catch (error) {
    console.log(error);
  }
}
