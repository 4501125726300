import React, { useEffect } from "react";
import { Row, Col, Accordion, Form, Button } from "react-bootstrap";
import { HelpDesk } from '../../../redux/ourHelp/ourHelpActions';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
const OurHelp = (props) => {
    const dispatch = useDispatch();
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const ourHelp = useSelector((state) => state?.ourHelp?.message)
    useEffect(() => {
        if (ourHelp?.status === 1) {
            reset()
        }
    }, [ourHelp,reset])
    const onSubmit = async (data) => {
        dispatch(HelpDesk(data));
    }
    return (

        <div className="container-width our-help">
            <Row>
                <Col lg={7}>
                    <div className="our-help-content">
                        <div className="ourhelp position-relative">
                            <h4><span className="orange">We are here to help you</span></h4>
                        </div>
                        <div className="accordain">
                            <Accordion defaultActiveKey="0" flush>
                                {
                                    props.faq?.length > 0 &&
                                    props.faq?.map((item, i) => (
                                        <Accordion.Item eventKey={i.toString()}>
                                            <Accordion.Header>
                                                <h5>{item.title}</h5>
                                            </Accordion.Header>
                                            <Accordion.Body className="position-relative">
                                                <p>{item.description}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))
                                }
                            </Accordion>
                        </div>
                    </div>
                </Col>
                <Col lg={5}>
                    <div className="ourhelp-form">
                        <div className="text-center">
                            <h4>Quick help desk</h4>
                            <p>Didn't find your answer?</p>
                        </div>
                        {/*CLIENT SIDE VALIDATION MESSAGES*/}
                        {errors.name && <div className="alert alert-danger alert-dismissible">
                            Please check the Name</div>}
                        {errors.email && <div className="alert alert-danger alert-dismissible">
                            Please check the Email</div>}
                        {errors.phone && <div className="alert alert-danger alert-dismissible">
                            Please Enter Valid Phone number</div>}
                        {errors.message && <div className="alert alert-danger alert-dismissible">
                            Please check the Message</div>}

                        {/*SERVER SIDE VALIDATION MESSAGE*/}

                        {
                            (ourHelp?.status === 0 && ourHelp.message) &&
                            Object.keys(ourHelp.message).map(key => <div className="alert alert-danger alert-dismissible">
                                {ourHelp.message[key][0]}
                            </div>)
                        }
                        {/*SERVER SIDE SUCCESS MESSAGE*/}
                        {
                            ourHelp?.status === 1 &&
                            <div className="alert alert-success">
                                {ourHelp?.message}
                            </div>
                        }
                        <Form method="post" onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group className="contact-form mb-4" controlId="formBasicEmail">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="name" {...register("name", { required: true, min: 1 })}
                                />
                            </Form.Group>

                            <Form.Group className="contact-form mb-4" controlId="formBasicPassword">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email"
                                    {...register("email", { required: true })} />
                            </Form.Group>
                            <Form.Group className="contact-form mb-4" controlId="formBasicPassword">
                                <Form.Label>Phone Number</Form.Label>
                               { /* eslint-disable no-useless-escape */}
                                <Form.Control type="phone number" {...register("phone")} />
                            </Form.Group>
                            <div className="contact-form mb-4">
                                <Form.Label htmlFor="inputPassword5" className="heading-form">Your Message</Form.Label>
                                <textarea id="shop-description" name="description" rows="4" cols="50" {...register("message", { required: true, min: 1})}></textarea>
                            </div>
                            <div className="text-end">
                                <Button className="btn-white" type="submit">Submit</Button>
                            </div>
                        </Form>
                    </div>
                </Col>
            </Row>
        </div>


    )
}
export default OurHelp;