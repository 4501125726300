import React, { useEffect } from "react";
import Footer from "../Home/Footer/Footer";
import Header from "../Home/Header/Header";
import Banner from "./Banner/Banner";
import PostDetail from "./PostDetail/PostDetail";
import { fetchPost } from '../../redux/blogPost/blogPostActions';
import { useDispatch, useSelector } from "react-redux";
const Post = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const dispatch = useDispatch();
    const { postList } = useSelector((state) => state);

    var location = window.location.href.split('/');
    let slug = location[4];
    useEffect(() => {
        dispatch(fetchPost(slug));
    }, [dispatch,slug]);
    return (
        <div className="wrapper">
            <Header />
            <Banner title = {postList?.post?.data?.title} loading={postList?.loading} />
            <PostDetail description={postList?.post?.data?.description} loading={postList?.loading} />
            <Footer />
        </div>
    )
}
export default Post;