import { REGIONS } from './regionTypes';

const initialState = {
   regionsList: [],
   success: false,
   loading: true,
   registered: false,
   error: false
}

const regionsReducer = (state = initialState, action) => {

   switch (action.type) {
      case REGIONS:
         return {
            ...state,
            regionsList: action.payload,
            success: true,
            loading: false,
            registered: true
         }

      default:
         return state
   }
}

export default regionsReducer
