import { REGIONS_AND_COUNTRIES, ERROR_STATE } from "./homeTypes";
import { apiHelper } from "../apiHelper"

export const RegionsAndCountries = () => async (dispatch) => {
  try {
    let res = await apiHelper("get", `fetch-regions-and-countries`, '')
    if (res?.data) {
      if (res?.data) {
        let { data } = res
        dispatch({
          type: REGIONS_AND_COUNTRIES,
          payload: data
        })
      }
      else {
        dispatch({
          type: ERROR_STATE
        })
      }
    };


  } catch (error) {
    console.log(error);
  }
}


