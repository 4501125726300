import { SEARCH_REGION_AND_COUNTRIES } from './searchRegionAndCountriesTypes';

const initialState = {
   searchRegionAndCountriesList: [],
   success: false,
   loading: true,
   registered: false,
   error: false
}

const searchRegionAndCountriesReducer = (state = initialState, action) => {

   switch (action.type) {
      case SEARCH_REGION_AND_COUNTRIES:
         return {
            ...state,
            searchRegionAndCountriesList: action.payload,
            success: true,
            loading: false,
            registered: true
         }

      default:
         return state
   }
}

export default searchRegionAndCountriesReducer
