import React, {useEffect } from 'react';
import Footer from "../Home/Footer/Footer";
import Header from "../Home/Header/Header";
import Banner from "./Banner/Banner";
import BlogInfo from "./BlogInfo/BlogInfo";

const Blog = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="wrapper">
            <Header />
            <Banner />
            <BlogInfo/>
            <Footer />
        </div>
    )
}
export default Blog;