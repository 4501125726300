import React from "react";
 
const TermsInfo = (props) => {
    return (
        <div className="privacy-info terms-info">
            <div dangerouslySetInnerHTML={{__html: props.description}} />
            {/* <div className="container-width">
                <div className="privacy-info-content mb-5">
                    <h2>Terms of service</h2>
                    <p>Proin ex ipsum, facilisis id tincidunt sed, vulputate in lacus. Donec pharetra faucibus leo, vitae vestibulum leo scelerisque eu. Nam enim dolor, porta at
                        ex ut, eleifend tristique metus. Integer sit amet auctor ligula, vel dictum orci. Nam feugiat interdum nisl. Ut id velit ac ante maximus gravida.</p>
                </div>
                <Row className="mb-lg-5 mb-md-5 mb-sm-4 mb-4">
                    <Col lg="6" className="mb-lg-5 mb-3">
                        <div className="privacy-boxes-content d-flex justify-content-between flex-lg-row flex-md-row flex-sm-row flex-column">
                            <div className="privacy-boxes-content-img me-5">
                                <Image src={termsimgone} fluid/>
                            </div>
                            <div className="privacy-boxes-content-info">
                                <h4>Terms</h4>
                                <p>Praesent efficitur lorem porttitor risus sollicitudin, id elementum sapien tincidunt. Maecenas convallis tempor metus, non porta libero semper
                                    id.Aliquam erat volutpat. Integer sit amet laoreet eros.</p>
                            </div>
                        </div>
                    </Col> 
                    <Col lg="6" className="mb-lg-5 mb-md-5 mb-sm-4 mb-4">
                        <div className="privacy-boxes-content d-flex justify-content-between flex-lg-row flex-md-row flex-sm-row flex-column">
                            <div className="privacy-boxes-content-img me-5">
                                <Image src={termsimgtwo} fluid/>
                            </div>
                            <div className="privacy-boxes-content-info">
                                <h4>Use license</h4>
                                <p>Praesent efficitur lorem porttitor risus sollicitudin, id elementum sapien tincidunt. Maecenas convallis tempor metus, non porta libero semper
                                    id.Aliquam erat volutpat. Integer sit amet laoreet eros.</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg="6" className="mb-lg-0 mb-md-5 mb-sm-4 mb-4">
                        <div className="privacy-boxes-content d-flex justify-content-between flex-lg-row flex-md-row flex-sm-row flex-column">
                            <div className="privacy-boxes-content-img me-5">
                                <Image src={termsimgthree} fluid/>
                            </div>
                            <div className="privacy-boxes-content-info">
                                <h4>Disclaimer</h4>
                                <p>Praesent efficitur lorem porttitor risus sollicitudin, id elementum sapien tincidunt. Maecenas convallis tempor metus, non porta libero semper
                                    id.Aliquam erat volutpat. Integer sit amet laoreet eros.</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="privacy-boxes-content d-flex justify-content-between flex-lg-row flex-md-row flex-sm-row flex-column">
                            <div className="privacy-boxes-content-img me-5">
                                <Image src={termsimgfour} fluid/>
                            </div>
                            <div className="privacy-boxes-content-info">
                                <h4>Limitations</h4>
                                <p>Praesent efficitur lorem porttitor risus sollicitudin, id elementum sapien tincidunt. Maecenas convallis tempor metus, non porta libero semper
                                    id.Aliquam erat volutpat. Integer sit amet laoreet eros.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="privacy-legal terms-legal">
                    <h2 className="mb-lg-4 mb-3">All others</h2>
                    <div className="privacy-legal-boxe">
                        <h4>Links</h4>
                        <p>Aliquam erat volutpat. Integer sit amet laoreet eros. Etiam feugiat sit amet urna eget efficitur. Fusce mollis velit sapien, rutrum tincidunt dui 
                        hendrerit a. Nam lacus elit, faucibus id efficitur porttitor, tincidunt et est. Quisque eget lacus non orci ultricies fringilla quis tincidunt urn 
                        Integer sit amet laoreet eros. Etiam feugia.</p>
                        <h4>Modifications</h4>
                        <p>Sed quis scelerisque ante. Duis neque arcu, egestas quis tempor in, tincidunt id mauris. Maecenas tincidunt porta nibh, sit amet semper elit vulputate vel. Proin tristique efficitur tempus. Pellentesque ac gravida elit. Donec viverra est malesuada nisi posuere ultricies. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Mauris in lobortis elit. Fusce porttitor sodales massa, et suscipit ligula rhoncus vitae. Cras dictum nunc et condimentum ullamcorper. Pellentesque scelerisque nibh eleifend tortor semper, nec facilisis mauris finibus. Integer porttitor auctor eros nec pellentesque.</p>
                        <h4>Product Download</h4>
                        <p>Sed quis scelerisque ante. Duis neque arcu, egestas quis tempor in, tincidunt id mauris. Maecenas tincidunt porta nibh, sit amet semper elit vulputate vel. Proin tristique efficitur tempus. Pellentesque ac gravida elit. Donec viverra est malesuada nisi posuere ultricies. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Mauris in lobortis elit. Fusce porttitor sodales massa, et suscipit ligula rhoncus vitae. Cras dictum nunc et condimentum ullamcorper. </p>
                        <p>Pellentesque scelerisque nibh eleifend tortor semper, nec facilempor in, tincidunt id mauris. Maecenas tincidunt porta nibh, sit amet semper elit vulputate vel. Proin tristique efficitur tempus. Pellentesque ac gravida elit. Donec viverra est malesuada teger porttitor auctor eros nec pellentesque.</p>
                        <h4>Refund Policy</h4>
                        <p>Sed quis scelerisque ante. Duis neque arcu, egestas quis tempor in, tincidunt id mauris. Maecenas tincidunt porta nibh, sit amet semper elit vulputate vel. Proin tristique efficitur tempus. Pellentesque ac gravida elit. Donec viverra est malesuada nisi posuere ultricies. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis. egestas. Mauris in lobortis elit. Fusce porttitor sodales massa, et suscipit ligula rhoncus vitae. Cras dictum nunc et condimentum ullamcorper. Pellentesque scelerisque nibh eleifend tortor semper, nec facilisis mauris finibus. Integer porttitor auctor eros nec pellentesque.</p>
                        <h4>Governing Law</h4>
                        <p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis. egestas. Mauris in lobortis elit. Fusce porttitor sodales massa, et suscipit ligula rhoncus vitae. Sed quis scelerisque ante. Duis neque arcu, egestas quis tempor in, tincidunt id mauris. Maecenas tincidunt porta nibh, sit amet semper elit vulputate vel. Proin tristique efficitur tempus. Pellentesque ac gravida elit. Donec viverra est malesuada nisi posuere ultricies.</p>
                        <ul className="m-0 mb-4 p-0">
                            <li>Aliquam erat volutpat. Integer sit amet laoreet eros</li>
                            <li>Etiam feugiat sit amet urna eget efficitur</li>
                            <li>Rutrum tincidunt dui hendrerit wam lacus eli faucibus id efficitur</li>
                            <li>Hincidunt et est isque eget lacus non orci ultricies fringilla</li>
                        </ul>
                    </div>
                </div>
            </div> */}


            
        </div>
    )
}
export default TermsInfo;