import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Settings } from '../../../redux/settings/settingActions';
import footerlogo from "../../../assets/images/footer-logo.svg";
import facebooklogo from "../../../assets/images/facebook.svg";
import twitterlogo from "../../../assets/images/twitter.svg";
import linkdinlogo from "../../../assets/images/linkdin.svg";
import pinterestlogo from "../../../assets/images/pinterest.svg";
import youtubelogo from "../../../assets/images/youtube.svg";
import { Image } from "react-bootstrap";
import { Link } from 'react-router-dom'
const Footer = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(Settings());

    }, [dispatch]);
    const settingData = useSelector((state) => state?.settings?.settingsData?.data?.settings)
    const cmsLinks = useSelector((state) => state?.settings?.settingsData?.data?.cmsLinks)
    return (
        <div className="footer">
            <div className="container-width">
                <div className="footer-content text-center">
                    <a href="/">
                        <div className="logo mb-4">
                            <Image src={footerlogo} fluid />
                        </div>
                    </a>
                    <div className="footer-social d-flex align-items-center justify-content-center">
                        {settingData?.facebook &&
                            <a href={settingData.facebook} target="_blank" rel="noreferrer">
                                <div className="social-icons-img d-flex align-items-center justify-content-center">
                                    <Image src={facebooklogo} fluid />
                                </div>
                            </a>
                        }
                        {settingData?.twitter &&
                            <a href={settingData.twitter} target="_blank" rel="noreferrer">
                                <div className="social-icons-img d-flex align-items-center justify-content-center">
                                    <Image src={twitterlogo} fluid />
                                </div>
                            </a>
                        }
                        {settingData?.linkedin &&
                            <a href={settingData.linkedin} target="_blank" rel="noreferrer">
                                <div className="social-icons-img d-flex align-items-center justify-content-center">
                                    <Image src={linkdinlogo} fluid />
                                </div>
                            </a>
                        }
                        {settingData?.pinterest &&
                            <a href={settingData.pinterest} target="_blank" rel="noreferrer">
                                <div className="social-icons-img d-flex align-items-center justify-content-center">
                                    <Image src={pinterestlogo} fluid />
                                </div>
                            </a>
                        }
                        {settingData?.youtube &&
                            <a href={settingData.youtube} target="_blank" rel="noreferrer">
                                <div className="social-icons-img d-flex align-items-center justify-content-center">
                                    <Image src={youtubelogo} fluid />
                                </div>
                            </a>
                        }
                    </div>
                    
                    <div className="footer-links d-flex flex-lg-row flex-md-row flex-sm-row flex-column align-items-center justify-content-center">
                        <Link to="/blog" >
                            <h6>Blog</h6>
                        </Link>
                        {cmsLinks?.length > 0 &&
                            cmsLinks.map((item, index) => (

                                <Link to={"/" + item.slug} key={index}>
                                    <h6 className="content-border">{item.title}</h6>
                                </Link>
                            ))
                        }
                    </div>
                    <p>{settingData?.footer_description}</p>
                </div>
                <div className="footer-copyright text-center">
                    <p className="mb-0">© {new Date().getFullYear()} eSIMLIST. All rights reserved</p>
                </div>
            </div>
        </div>
    )
}
export default Footer;