import React from "react";


const PageNotFound = (props) => {

    return (
        <div className="page-found">
            <div className="container-width">
                <div className="page-wrapper text-center">
                    <h1 ><span className="orange">We are sorry</span></h1>
                    <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
                    <a href="/">Back To Homepage</a>
                </div>
            </div>
        </div>


    )
}
export default PageNotFound;