import React from "react";
import { Spinner } from "../../Common/Spinner/Spinner"

const Banner = (props) => {
    return (
        <div className="second-banner blog-banner">

            <div className="container-width">
                {props?.loading ? (
                    <Spinner />
                ) : (
                    <div className="second-banner-content text-center">

                        <h1 ><span className="orange">{props?.title}</span></h1>
                    </div>
                )}
            </div>
        </div>
    )
}
export default Banner;